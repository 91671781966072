@import '../App.scss';

.background {
    height: 78vh;
    min-height: 300px;
    width: 100%;
    z-index: 0;
    overflow: hidden;
    position: relative;

    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.title-wrapper {
    height: 100%;
    text-align: center;
}

.title {
    color: $title-color;
    font-family: $title-font;
    font-weight: $title-weight;
    font-size: 6em;
    margin-top: -50px;
    margin-bottom: 0;
}

.subtitle {
    color: $title-color;
    margin-top: -20px;
    font-size: 2.5em;
    font-family: $body-font;
    font-weight: $body-weight;
}

@include less-than-lg {
    .background {
        background-attachment: scroll;
    }

    .title {
        font-size: 4em;
    }

    .subtitle {
        font-size: 2em;
    }
}