@import '../App.scss';

.highlight-section-wrapper {
    background-color: $trim-light-color;
    border-radius: $card-border-radius;
    padding: 30px 0;
}

@include less-than-lg {
    .highlight-section-wrapper .article-card {
        margin-bottom: 15px;
    }
}