@import '../App.scss';

.date-pub {
    font-size: 1.2em;
}

.article-wrapper {
    font-family: $title-font;
    font-weight: $title-weight;
    color: $title-color;
}

.article-text-wrapper {
    margin-top: 25px;
    font-family: $body-font;
    font-weight: $body-weight;
}