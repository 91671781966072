@import '../App.scss';

.overview-text-wrapper {
    margin-top: 0;
}

@include less-than-lg {
    .overview-text-wrapper {
        margin-top: 15px;
    }
}