@import '../App.scss';

.company-logo {
    height: 60px;
    margin: 10px 20px;
}

.nav-link {
    line-height: 80px;
    font-size: 1.25em;
}

.mobile-nav-link {
    margin: 0;
    font-size: 1.25em;
}

.menu {
    //position: fixed;
    //z-index: 1;
    //width: 100%;
}

// Gross work-around for now
.menu > .ant-menu-overflowed-submenu{
    line-height: 80px;
}

.mobile-menu-open {
    position: fixed;
    width: 60%;
    margin-left: 20%;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
    bottom: 10px;
    border-radius: 10px;
    height: 50px;
    z-index: 1001;
    font-family: $title-font;
    font-weight: $title-weight;
    color: $title-color;
}

.nav-wrapper {
    width: 100%;
    height: 80px;
    background-color: white;
}

.nav-items {
    text-align: right;
    height: 80px;
    line-height: 80px;
    margin-right: 10px;
}

.nav-item {
    display: inline ;
    text-align: center;
    line-height: 80px;
    cursor: pointer;
    font-size: 1.25em;
    margin: 0;
    padding: 26px 15px;
    border-bottom: none;
    transition: 0.2s;
    color: $title-color;
    text-decoration: none;
}

.nav-item:hover {
    border-bottom: 4px solid $antd-blue;
    color: $antd-blue;
}

.hamburger-container {
    cursor: pointer;
    display: inline-block;
    padding: 20px 5px 20px 5px;
    line-height: 60px;
    margin-right: 20px;
    text-align: right;
}

.ham-bar1, .ham-bar2, .ham-bar3 {
    width: 35px;
    height: 5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
}

/* Rotate first bar */
.change .ham-bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px) ;
    transform: rotate(-45deg) translate(-9px, 6px) ;
}

/* Fade out the second bar */
.change .ham-bar2 {
    opacity: 0;
}

/* Rotate last bar */
.change .ham-bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px) ;
    transform: rotate(45deg) translate(-8px, -8px) ;
}

.mobile-nav {
    width: 100%;
    display: none;
}

.hamburger-menu-container {
    text-align: right;
}

.desktop-nav {
    width: 100%;
    display: inline-block;
}

@include less-than-lg {
    .mobile-nav {
        display: inline-block;
    }

    .desktop-nav {
        display: none;
    }
}