@import '../App.scss';

.footer-wrapper {
    padding: 20px 0;
    background-color: $trim-light-color;
}

.contact-information {
    margin-top: 0;
}

.footer-text {
    font-family: $body-font;
    font-weight: $body-weight;
    font-size: 1.1em;
    color: $section-body-color;
}

.media-icon:hover {
    text-decoration: none;
}

.media-icon:focus {
    text-decoration: none;
}

.media-icon-img {
    width: 40px;
    margin: 0 10px;
}

.media-icon-img:hover {
    cursor: pointer;
}

@include less-than-lg {
    .contact-information {
        margin: 20px 0;
    }
}