@import '../App.scss';

.carousel-image {
    max-height: 800px;
}

.carousel-image > img {
    border-radius: 0;
}

.google-map-wrapper {
    min-height: 400px;
}
