@import '../../App.scss';

.img {
    width: 100%;
    border-radius: $img-border-radius;
}

.text-link {
    text-align: center;
    border-bottom: $section-body-color solid 2px;
    font-size: 1.25em;
    color: $section-body-color;
    cursor: pointer;
}

.text-link:hover {
    color: #666667;
    transition: 0.2s;
    border-bottom: none;
}

.inline-text {
    font-family: $body-font;
    font-weight: $body-weight;
    font-size: 1.5em;
    color: $section-body-color;
}

.inline-link {
    font-family: $body-font;
    font-weight: $body-weight;
    text-decoration: none;
    color: $section-body-color;
    text-decoration: underline;
    //border-bottom: 1px solid $section-body-color;
    cursor: pointer;
}

.inline-link:hover {
    color: #666667;
    transition: 0.2s;
    text-decoration: none;
}

.section {
    margin: 40px 0;
}

.section-title {
    font-family: $title-font;
    font-weight: $title-weight;
}

.section-body {
    font-size: 1.5em;
    font-family: $body-font;
    font-weight: $body-weight;
    color: $section-body-color;
}

@include less-than-lg {
    .section-title {
        font-size: 3em;
    }
}
