@import '../App.scss';

.article-card {
    min-height: 230px;
    border-radius: $card-border-radius;
}

.article-title {
    white-space: normal;
}

.star-wrapper {
    width: 24px;
}

.star-icon {
    vertical-align: text-top;
}
