@import '~antd/dist/antd.css';

// Color variable definitions
$black: #000000;
$off-black: #262626;
$dark-grey: #3d3d3d;
$light-blue: #dceaf5;
$antd-blue: #1890ff;


$title-color: $off-black;
$subtitle-color: $off-black;
$section-body-color: $off-black;
$trim-light-color: $light-blue;
$trim-color: $antd-blue;

// Other variable definitions
$img-border-radius: 5px;
$card-border-radius: 5px;

// Font variable definitions

$section-body-font: 'Helvetica Neue';
$title-font: 'Noto Sans', sans-serif;
$title-weight: 700;

$body-font: 'Open Sans', sans-serif;
$body-weight: 400;
$body-bold-weight: 600;

// -- Mixins --
@mixin less-than-lg {
    @media (max-width: 992px) { @content; }
}
//!-- Mixins --