@import "../App.scss";

.team-section-name {
    // !important to override the styles from antd :/
    font-weight: $title-weight !important;
    font-size: 2.25em !important;
    color: $title-color !important;

    font-family: $title-font;
    margin-top: 15px;
}

.team-section-title {
    // !important to override the styles from antd :/
    margin-top: 0 !important;
    font-size: 1.75em !important;
    color: $subtitle-color !important;
    font-weight: $body-bold-weight !important;

    font-family: $body-font;
}

.cristina-headshot-wrapper {
    margin-top: 10px;
}